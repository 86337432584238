import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { OrderStatusService } from 'services/order-status-service';

@autoinject
export class OrderStatusNew {
  private orderstatus: Models.OrderStatus = new Models.OrderStatus();

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private orderStatusService: OrderStatusService
  ) {}

  private createOrderStatus() {
    this.orderStatusService
      .post(this.orderstatus)
      .then((res) => {
        this.eventAggregator.publish('orderStatusListReset', 1);
        this.toastService.showSuccess('orderstatus.created');
        this.router.navigateToRoute('order-status-detail', { Id: res.Id });
      })
      .catch((err) => this.errorService.handleError(err));
  }
}
