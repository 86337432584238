import { HistoryDialog } from './../history-dialog/history-dialog';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { PLATFORM } from 'aurelia-framework';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetOrderService } from 'services/net-order-service';
import { NetService } from 'services/net-service';
import { OrderStatusService } from 'services/order-status-service';
import { ReportService } from 'services/report-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class OrderDetailMain {
  private netorder: Models.NetOrder;
  private net: Models.Net;
  private statuses: Array<Models.OrderStatus> = [];
  private printOrderProgress: boolean = false;

  private subscription: Subscription;

  protected is2021Net = false;
  protected netLoaded = false;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netOrderService: NetOrderService,
    private netService: NetService,
    private reportService: ReportService,
    private router: Router,
    private toastService: ToastService,
    private orderStatusService: OrderStatusService,
    private deleteDialogService: DeleteDialogService
  ) { }

  private configureRouter(config: RouterConfiguration, router) {
    config.map([
      {
        route: [''],
        name: 'order-detail-order-info',
        moduleId: PLATFORM.moduleName('./order-detail-order-info'),
        tabindex: 0,
        entityShortName: 'order'
      },
      {
        route: ['dimension'],
        name: 'order-detail-dimension',
        moduleId: PLATFORM.moduleName('../offer/form-dimension'),
        tabindex: 1,
        entityShortName: 'order'
      },
      {
        route: ['netting'],
        name: 'order-detail-netting',
        moduleId: PLATFORM.moduleName('../offer/form-netting'),
        tabindex: 2,
        entityShortName: 'order'
      },
      {
        route: ['rope'],
        name: 'order-detail-rope',
        moduleId: PLATFORM.moduleName('../offer/form-rope'),
        tabindex: 3,
        entityShortName: 'order'
      },
      {
        route: ['misc'],
        name: 'order-detail-misc',
        moduleId: PLATFORM.moduleName('../offer/form-misc'),
        tabindex: 4,
        entityShortName: 'order'
      },
      {
        route: ['center-bottom'],
        name: 'simple-offer-tab-center-bottom',
        moduleId: PLATFORM.moduleName('../simple-offer/simple-offer-tab-center-bottom'),
        tabindex: 5,
        entityShortName: 'order',
      },
      {
        route: ['extra-equipment'],
        name: 'simple-offer-tab-extra-equipment',
        moduleId: PLATFORM.moduleName('../simple-offer/simple-offer-tab-extra-equipment'),
        tabindex: 6,
        entityShortName: 'order',
      },
      {
        route: ['impregnation'],
        name: 'order-detail-impregnation',
        moduleId: PLATFORM.moduleName('../offer/form-impregnation'),
        tabindex: 7,
        entityShortName: 'order'
      },
      {
        route: ['preparation'],
        name: 'order-detail-preparation',
        moduleId: PLATFORM.moduleName('../offer/form-preparation'),
        tabindex: 8,
        entityShortName: 'order'
      }
    ]);
  }

  private activate(params) {
    this.netOrderService
      .get(params.Id + '?$expand=Customer,Contact,Producer,OrderStatus,NetOffer')
      .then((res) => {
        this.netorder = res;
        this.getNet(this.netorder.NetId);
      })
      .catch((err) => this.errorService.handleError(err));

    this.orderStatusService.getAllCached()
      .then((res) => {        
        this.statuses = res.filter(x => !x.IsDeleted);
      })
      .catch((err) => this.errorService.handleError(err));

      // hack to recalculate length/weight for order, should refactor this later, but
      // need to get a quick solution in place
      this.subscription = this.eventAggregator.subscribe('offer-refresh', (e) => {      
        switch (e) {
          case 'price':        

            if (this.net &&  !this.net.ApprovedDate) {
              this.recalculateOrder(this.net.Id);          
            }
            break;  
  
          default:
            break;
        }
  
      });
  }

  private detached() {
    if(this.subscription) {
      this.subscription.dispose();
    }
  }

  private deactivate() {
    if(this.subscription) {
      this.subscription.dispose();
    }
  }  

  private recalculateOrder(netId: number) {
    this.netService.recalculateData(netId)
      .then(res => {
        this.eventAggregator.publish('refresh-calculated-dimensions');
      })
      .catch(err => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
      .get(id + '?$expand=NetDimension($expand=NetStandard)')
      .then((res) => {
        this.is2021Net = res.NetDimension.NetStandard?.Version === Models.NetStandardVersion.NS9415_2021;
        this.netLoaded = true;
        this.net = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private confirmBeforeCreateNets() {
    return this.dialogService.open({
      viewModel: Prompt,
      model: {
        header: 'dialog.createNetsHeading',
        message: 'dialog.createNetsAreYouSure',
        actions: {
          delete: { enabled: false },
          save: { enabled: false },
          cancel: { enabled: true, t: 'dialog.cancel' },
          dontsave: { enabled: false },
          createNets: { enabled: true, t: 'dialog.createNets'  }
        }
      }
    }).whenClosed((response) => {
      if (response.wasCancelled) {
        return false;
      } else {
        const result = response.output;
        if (result === 'creatednets') {
          this.createNets();
          return false;
        } else {
          return true;
        }
      }
    });
  }

  private createNets() {
    this.netOrderService.createNets(this.netorder.Id)
      .then((res) => {
        this.toastService.showSuccess('order.netsCreated');
        this.eventAggregator.publish('nets-created');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private createNetOrder() {
    this.router.navigate('#/order/' + this.netorder.Id + '/net/' + this.net.Id + '/order');
  }

  private changeNetOrderStatus(netorderId, statusId) {
    this.netorder.Contact = null;
    this.netorder.Customer = null;
    this.netorder.OrderStatus = null;
    this.netorder.Producer = null;
    this.netorder.NetOffer = null;
    this.netorder.OrderStatusId = statusId;
    this.netOrderService.put(this.netorder, netorderId)
      .then((result) => {
        this.eventAggregator.publish('dropdownClose', 1);
        this.toastService.showSuccess('order.updated');

        this.router.navigateToRoute(
          this.router.currentInstruction.config.name,
          this.router.currentInstruction.params,
          { replace: true }
        );
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private printOrder() {
    if (this.printOrderProgress) { return; }
    this.printOrderProgress = true;
    this.reportService.getProductionOrderReport(this.netorder.NetId)
      .then((res) => {
        this.toastService.showSuccess('document.downloaded');
        this.printOrderProgress = false;
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.printOrderProgress = false;
      });
  }

  private showHistory() {
    let viewModel = {
      EntityType: 'Net',
      EntityId: this.netorder.NetId
    };

    this.dialogService.open({ viewModel: HistoryDialog, model: viewModel, lock: false, position: (mc, mo) => {} });  
  }
}
