import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable, BindingEngine, observable } from 'aurelia-framework';
import { activationStrategy, Router } from 'aurelia-router';
import { Models } from 'models/core';
import { UserModels } from 'models/UserModels';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { ListSettingsService } from 'services/list-settings-service';
import { NetOrderService } from 'services/net-order-service';
import { OrderStatusService } from 'services/order-status-service';
import { ToastService } from 'services/toast-service';
import { UserService } from 'services/user-service';

@autoinject
export class OrderListTable {
  private tableData: Array<any>;
  private netOrderToUpdate: Models.NetOrder;
  private customers: Array<Models.Customer> = [];
  private ready: boolean;
  private searchInputHasFocus: boolean = false;
  private statuses: Array<Models.OrderStatus> = [];
  private orderOwnerFilter: string = 'all';
  private orderTotalItems: number;

  private ORDER_FILTERS_KEY = 'ORDER_FILTERS_KEY';
  private showSearchDefault: boolean = false;

  @bindable private params: any;
  @bindable private orderPageSize: number = 25;
  @bindable private context: string = '';
  @bindable private orderCurrentPage: number = 1;
  @bindable private customerid;
  @bindable private userId;

  private currentUser: UserModels.User;

  private tableViewType: number = 1;
  private tableViewVerticalLines: boolean = false;
  private tableMargin: number = 2;

  private filters = {
    orderStatusIds: null,
    orderCustomerId: null,
    orderResponsibleUserId: '',
    orderSearchText: null,
    orderOrderBy: 'ID',
    orderOrderByDirection: 'DESC',
    orderTop: this.orderPageSize,
    orderSkip: null,
    orderProducerId: null
  };

  // statuses (id) to be disabled at startup
  private disabledStatuses = [ 5 ];

  constructor(
    private netOrderService: NetOrderService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private orderStatusService: OrderStatusService,
    private userService: UserService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private bindingEngine: BindingEngine,
    private toastService: ToastService,
    private listSettingsService: ListSettingsService
  ) {
  }

  private determineActivationStrategy() {
    return activationStrategy.replace;
  }

  private getFilterKey() {
    return this.ORDER_FILTERS_KEY + (this.context ? '_' + this.context : '');
  }

  private attached() {
    Promise.all([
      this.orderStatusService.getAllCached(),
      this.userService.getCurrentUser(),
      this.listSettingsService.getDefaultPageSize()
    ]).then((responses) => {

      const statuses = responses[0];
      statuses.forEach((r, index) => {
        r.Visible = true;
      });
      this.statuses = statuses.filter((x) => !x.IsDeleted);

      this.currentUser = responses[1];
      this.userId = this.currentUser.Id;
      this.filters.orderProducerId = this.currentUser.ProducerId;

      if (localStorage.getItem(this.getFilterKey())) {
        this.filters = JSON.parse(localStorage.getItem(this.getFilterKey()));

        this.statuses = statuses.filter((x) => !x.IsDeleted).map((it) => {
          if (this.filters.orderStatusIds && this.filters.orderStatusIds.indexOf(it.Id) === -1) {
            it.Visible = false;
          }
          return it;
        });
      } else {

        // this will be used if no previous filters have been set by the user in localStorage
        if (this.context === 'sales-dashboard') {
          this.filters.orderTop = this.orderPageSize;
          this.filters.orderResponsibleUserId = this.userId;
        }

        if (this.context !== 'customer-view') {
          this.statuses = this.statuses.map((it) => {
            if (this.disabledStatuses.indexOf(it.Id) !== -1) {
              it.Visible = false;
            }
            return it;
          });
        }

        if (this.params.orderCurrentPage > 1) {
          this.filters.orderSkip = (this.params.orderCurrentPage - 1) * this.filters.orderTop;
          this.orderCurrentPage = this.params.orderCurrentPage;
        }

        this.saveFilters();
      }

      if (this.customerid) {
        this.filters.orderCustomerId = this.customerid;
      }

      if (this.filters.orderSearchText && this.filters.orderSearchText !== '') {
        this.showSearchDefault = true;
      }

      this.bindingEngine
        .propertyObserver(this.filters, 'orderResponsibleUserId')
        .subscribe((newValue, oldValue) => {
          this.filterValueChanged('orderResponsibleUserId', newValue, oldValue);
        });

      this.bindingEngine
        .propertyObserver(this.filters, 'orderSearchText')
        .subscribe((newValue, oldValue) => {
          this.filterValueChanged('orderSearchText', newValue, oldValue);
        });

        // list settings
      this.orderPageSize = responses[2];

      this.getNetOrders(true);

    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getFilteredStatuses() {
    const a = [];
    this.statuses.forEach((status) => {
      if (status.Visible) {
        a.push(status.Id);
      }
    });
    return a;
  }

  private closeFilters() {
    this.closeDropdown(null);
  }

  private updateVisibleStatuses(event) {
    const statuses = this.getFilteredStatuses();
    const oldStatuses = this.filters.orderStatusIds;
    this.filters.orderStatusIds = statuses;

    this.filterValueChanged('orderStatusIds', statuses, oldStatuses);
  }

  private allVisibleStatuses(event) {
    this.statuses.forEach((status) => {
      status.Visible = true;
    });
    this.updateVisibleStatuses(false);
  }
  private noVisibleStatuses(event) {
    this.statuses.forEach((status) => {
      status.Visible = false;
    });
    this.updateVisibleStatuses(false);
  }

  private filterValueChanged(key, newValue, oldValue) {
    if (newValue === oldValue) {
      return;
    }
    // Always go back to first page when changing filter values
    this.orderCurrentPage = 1;
    this.pushState();

    this.saveFilters();
    this.getNetOrders();
  }

  private sortTable(field) {
    if (this.filters.orderOrderBy === field) {
      this.filters.orderOrderByDirection = this.filters.orderOrderByDirection === 'DESC' ? 'ASC' : 'DESC';
    } else {
      this.filters.orderOrderBy = field;
      this.filters.orderOrderByDirection = 'DESC';
    }

    this.pushState();

    this.saveFilters();

    this.getNetOrders();
  }

  private pushState() {
    const stateObj = {
      orderCurrentPage: this.orderCurrentPage
    };

    const baseUrl = '#/orders';

    const queryString = this.buildQueryString(stateObj);
    history.pushState(stateObj, 'orders', baseUrl + '?' + queryString);

    return stateObj;
  }

  private saveFilters() {
    localStorage.setItem(this.getFilterKey(), JSON.stringify(this.filters));
  }

  private buildQueryString(obj) {
    const arr = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        // dont include keys where value is null
        if (obj[p]) {
          arr.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
      }
    }
    return arr.join('&');
  }

  private orderCurrentPageChanged(newValue, oldValue) {

    if (parseInt(newValue, 10) === parseInt(oldValue, 10)) {
      return;
    }

    this.pushState();

    this.filters.orderSkip = (newValue - 1) * this.filters.orderTop;
    this.getNetOrders();
  }

  private getNetOrders(onActivate?: boolean) {
    this.filters.orderTop = this.orderPageSize;
    this.netOrderService.getList(this.filters)
      .then((res) => {
        this.ready = true;

        this.orderTotalItems = res.headers.get('x-total-count');

        return res.text().then((responseText) => {
          this.tableData = JSON.parse(responseText);
        });

      })
      .catch((err) => this.errorService.handleError(err));
    // Hent orderStatuses
  }

  private customerIdToName(id) {
    return this.customers.filter((customer) => customer.Id === id).map((customer) => customer.Name);
  }

  private setSearchFocus(event) {
    if (event.detail) {
      this.searchInputHasFocus = event.detail.value;
    }
    if (!this.searchInputHasFocus) {
      this.filters.orderSearchText = null;
      this.getNetOrders();
    }
  }

  private toggleStatuses(event) {
    event.stopPropagation();
  }

  private changeNetOrderStatus(netorderId, statusId) {
    this.ready = false;
    this.netOrderService.get(netorderId)
      .then((res) => {
        this.netOrderToUpdate = res;
        this.netOrderToUpdate.OrderStatusId = statusId;
        this.netOrderService.put(this.netOrderToUpdate, netorderId)
          .then((result) => {
            this.toastService.showSuccess('order.updated');
            this.getNetOrders();
          })
          .catch((err) => this.errorService.handleError(err));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private closeDropdown(event) {
    this.eventAggregator.publish('dropdownClose', 1);
  }
}
