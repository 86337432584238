import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { OrderStatusService } from 'services/order-status-service';

@autoinject
export class OrderStatusDetail {
  private orderstatus: Models.OrderStatus;

  private constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private router: Router,
    private toastService: ToastService,
    private orderStatusService: OrderStatusService
  ) {}

  private activate(params) {
    this.orderStatusService
      .get(params.Id)
      .then( (res) => {
        this.orderstatus = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateOrderStatus() {
    this.orderStatusService
      .put(this.orderstatus, this.orderstatus.Id)
      .then((res) => {
        this.eventAggregator.publish('orderStatusListReset', 1);
        this.toastService.showSuccess('orderstatus.updated');
        this.router.navigateToRoute('order-status-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteOrderStatus() {
    this.orderStatusService
      .delete(this.orderstatus.Id)
      .then((res) => {
        this.eventAggregator.publish('orderStatusListReset', 1);
        this.toastService.showSuccess('orderstatus.deleted');
        this.router.navigateToRoute('order-status-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoParentView() {
    this.eventAggregator.publish('orderStatusListReset', 1);
    this.router.navigateToRoute('order-status-list');
  }
}
