import { HttpClient } from 'aurelia-fetch-client';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { CreateOrderDto } from 'models/OrderModels';
import { UserModels } from 'models/UserModels';
import { AddressService } from 'services/address-service';
import { AuthHttpClient } from 'services/auth-http-client';
import { ContactService } from 'services/contact-service';
import { CustomerService } from 'services/customer-service';
import { DepartmentService } from 'services/department-service';
import { ErrorService } from 'services/error-service';
import { SiteService } from 'services/site-service';
import { UserService } from 'services/user-service';

@autoinject
export class OrderFormBasic {
  @bindable private netorder: any;
  @bindable private customers: Array<Models.Customer>;
  @bindable private departments: Array<Models.Department>;
  @bindable private sites: Array<Models.Site>;
  @bindable private contacts: Array<Models.Contact>;
  @bindable private addresses: Array<Models.Address>;
  @bindable private context: string;

  // BECAUSE OF BUG: If two select2-comp. uses the same source of data,
  // the whole app will just freeze / crash.
  private contacts2: Array<Models.Contact>;

  private httpClient = new HttpClient();
  private userlist: Array<UserModels.User>;

  constructor(
    private addressService: AddressService,
    private contactService: ContactService,
    private customerService: CustomerService,
    private departmentService: DepartmentService,
    private errorService: ErrorService,
    private siteService: SiteService,
    private userService: UserService
  ) { }

  private attached() {
    this.customerService
      .getAll('?$orderby=Name')
      .then((res) => {
        this.customers = res;
        this.getUsers();
      })
      .catch((err) => this.errorService.handleError(err));

    if (this.netorder.CustomerId && this.netorder.CustomerId !== '') {
      this.getDepartments(this.netorder.CustomerId);
      this.getAddresses(this.netorder.CustomerId);
      this.getContacts(this.netorder.CustomerId);

      if (this.netorder.ResponsibleUserId) {
        this.netorder.ResponsibleUserId = this.netorder.ResponsibleUserId;
      }

      if (this.netorder.DepartmentId) {
        const event = {
          detail: {
            value: this.netorder.DepartmentId
          }
        };
        this.setDepartment(event);
      }

    }
  }

  private getUsers() {
    this.userService
      .getAll()
      .then((res) => {
        this.userlist = res;

        if (this.context === 'new') {

          this.userService.getCurrentUser()
            .then((result) => {
              this.netorder.ResponsibleUserId = result.Id;
              this.setResponsibleUser(result.Id);
            })
            .catch((err) => this.errorService.handleError(err));
        }

      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getContacts(CustomerId) {
    this.contactService.getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.contacts = res.filter(x => x.IsResponsibleForOffers || (!x.IsResponsibleForOffers && !x.IsResponsibleForUnloading));

        // TMP. BUG-FIX: #154
        this.contacts2 = JSON.parse(JSON.stringify(res.filter(x => x.IsResponsibleForUnloading || (!x.IsResponsibleForOffers && !x.IsResponsibleForUnloading))));

      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getDepartments(CustomerId) {
    this.departmentService.getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.departments = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getAddresses(CustomerId) {
    this.addressService
      .getAll('?$filter=CustomerId eq ' + CustomerId)
      .then((res) => {
        this.addresses = this.addressService.addFullAddressProperty(res);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getSites(CustomerId) {
    let dep: string = '';

    if (this.netorder.DepartmentId) {
      dep = '&DepartmentId eq ' + this.netorder.DepartmentId;
    }
    this.siteService.getAll('?$filter=CustomerId eq ' + this.netorder.CustomerId + dep)
      .then((res) => {
        this.sites = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private setCustomer(event) {
    if (event.detail.value !== this.netorder.CustomerId) {
      this.sites = [];
      this.departments = [];
      this.netorder.CustomerId = event.detail.value;
      // this.getDepartments(this.netorder.CustomerId);
      this.getAddresses(this.netorder.CustomerId);
      this.getContacts(this.netorder.CustomerId);
    }
  }

  private setDepartment(evt) {

    if (evt.detail.value !== this.netorder.CustomerId) {
      this.sites = [];
      this.getSites(this.netorder.CustomerId);

    }
    this.netorder.DepartmentId = evt.detail.value;
  }

  private setResponsibleUser(value) {
    if (!value || value.toString() === (this.netorder.ResponsibleUserId || '').toString() ) {
      return;
    }
    this.netorder.ResponsibleUserId = value.toString();
  }

}
