import { autoinject } from 'aurelia-framework';
import { NetOrderService } from './../../services/net-order-service';

@autoinject
export class OrderList {
  private params: any;

  private ORDER_FILTERS_KEY = 'ORDER_FILTERS_KEY_full-view';

  constructor(private netOrderService: NetOrderService) {

  }


  private activate(params) {
    this.params = params;
  }

  exportDetailedReport() {
    let filters = JSON.parse(localStorage.getItem(this.ORDER_FILTERS_KEY));    
    this.netOrderService.exportDetailedReport(filters);
  }

  export() {
    let filters = JSON.parse(localStorage.getItem(this.ORDER_FILTERS_KEY));    
    this.netOrderService.exportList(filters);
  }
}
