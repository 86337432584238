import { DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, LogManager } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { CustomerService } from 'services/customer-service';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { NetOrderItemService } from 'services/net-order-item-service';
import { NetOrderService } from 'services/net-order-service';
import { NetService } from 'services/net-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';

const logger: Logger = LogManager.getLogger('order-detail-order-info');

@autoinject
export class OrderDetailOrderInfo {
  private order: Models.NetOrder;
  private net: Models.Net;
  protected customer: Models.Customer;
  protected netOrderItems: Array<Models.NetOrderItem>;

  private originalObjectOrder: Models.NetOrder;
  private originalObjectNet: Models.Net;
  private nextTabIndex: number = null;

  private subscription: Subscription;

  constructor(
    private customerService: CustomerService,
    private eventAggregator: EventAggregator,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private netOrderService: NetOrderService,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility,
    private netOrderItemService: NetOrderItemService,
    private deleteDialogService: DeleteDialogService
  ) {}

  protected activate(params: { Id: number; NetId: number }) {
    this.getOrder(params.Id);
    this.getNet(params.NetId);
    this.getNetOrderItems(params.Id);
    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex: number) => {
      this.nextTabIndex = tabIndex;
    });
    // Referesh order object after creating nets
    this.subscription = this.eventAggregator.subscribe('nets-created', () => {
      this.getOrder(params.Id);
      this.getNetOrderItems(params.Id);
    });
  }

  protected detached() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private getOrder(id: number) {
    this.netOrderService
      .get(id + '?$expand=DeliveryAddress,UnloadingContact,NetOffer')
      .then((res) => {
        this.order = res;
        this.getCustomer(this.order.CustomerId);
        this.updateOriginalObjectOrder();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id: number) {
    this.netService
      .get(id)
      .then((res) => {
        this.net = res;
        this.updateOriginalObjectNet();
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getCustomer(id: number) {
    this.customerService
      .get(id)
      .then((res) => {
        this.customer = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getNetOrderItems(id: number) {
    this.netOrderItemService
      .getAll('?$filter=NetOrderId eq ' + id + '&$expand=Net')
      .then((res) => {
        this.netOrderItems = res;
        if (this.order) {
          this.order.NumberOfNets = res.length;
        }
      })
      .catch((err) => this.errorService.handleError(err));
  }

  protected canDeactivate() {
    if (
      (this.originalObjectNet && !this.utility.areEqual(this.net, this.originalObjectNet)) ||
      (this.originalObjectOrder && !this.utility.areEqual(this.order, this.originalObjectOrder))
    ) {
      // tslint:disable-next-line:no-console
      logger.debug('original (net): ' + this.originalObjectNet, 'current: ' + this.net);
      // tslint:disable-next-line:no-console
      logger.debug('original (order): ' + this.originalObjectOrder, 'current: ' + this.order);
      return this.dialogService
        .open({
          viewModel: Prompt,
          model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' },
        })
        .whenClosed((response) => {
          if (response.wasCancelled) {
            return false;
          } else {
            const result = response.output;
            if (result === 'save') {
              // save the nettingtype and let that function handle the rest of the logic
              this.updateNetAndOrder();
              return false;
            } else {
              this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
              return true;
            }
          }
        });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private updateNetAndOrder() {
    this.order.DeliveryAddress = null;
    this.order.Items = null;
    delete this.order.UnloadingContact;

    this.netService
      .put(this.net, this.net.Id)
      .then(() => {
        this.netOrderService
          .put(this.order, this.order.Id)
          .then(() => {
            this.updateOriginalObjectOrder();
            this.getOrder(this.order.Id);
            this.getNet(this.net.Id);
            this.toastService.showSuccess('order.updated');
          })
          .catch((err) => this.errorService.handleError(err));
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private cancel() {
    this.router.navigateToRoute('order-list');
  }

  private updateOriginalObjectOrder() {
    if (!this.order) {
      return;
    }
    this.originalObjectOrder = JSON.parse(JSON.stringify(this.order));
  }

  private updateOriginalObjectNet() {
    if (!this.net) {
      return;
    }
    this.originalObjectNet = JSON.parse(JSON.stringify(this.net));
  }

  protected async addOrderItem() {
    await this.netOrderService.addOrderItem(this.order.Id);
    this.toastService.showSuccess('order.netOrderItemAdded');
    this.getNetOrderItems(this.order.Id);
  }

  protected deleteNetOrderItem(item: { Id: number; NetId: number; Net: Models.Net; Locked: boolean }) {
    // if item doesn't have a NetId, we delete it right away
    if (!item.NetId) {
      return this.deleteDialogService.confirmBeforeDelete(() => {
        this.netOrderItemService
          .deleteNetOrderItem(item.Id)
          .then(() => {
            this.toastService.showSuccess('order.netOrderItemDeleted');
            this.getNetOrderItems(this.order.Id);
          })
          .catch((err) => this.errorService.handleError(err));
      });
    }

    if (!(item.Net.AquacomId == null && item.Net.Locked === false)) {
      return this.toastService.showError('order.netOrderItemDeleteDeclined');
    }

    void this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.netOrderItemService
          .deleteNetOrderItem(item.Id)
          .then(() => {
            this.toastService.showSuccess('order.netOrderItemDeleted');
            this.getNetOrderItems(this.order.Id);
          })
          .catch((err) => this.errorService.handleError(err));
      },
      'dialog.deleteHeading',
      'order.deleteNetOrderItemWithNet'
    );
  }
}
