import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PLATFORM } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { NavigationInstruction, Next, Redirect, Router, RouterConfiguration } from 'aurelia-router';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from 'services/error-service';
import { NetOrderItemService } from 'services/net-order-item-service';
import { NetOrderService } from 'services/net-order-service';
import { NetService } from 'services/net-service';
import { ReportService } from 'services/report-service';
import { ToastService } from 'services/toast-service';
import { Utility } from 'utility';

@autoinject
export class OrderEdit {

  private netorder: Models.NetOrder = new Models.NetOrder();
  private net: Models.Net;
  private originalObject;
  private nextTabIndex: number = null;

  constructor(
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private netOrderService: NetOrderService,
    private netService: NetService,
    private reportService: ReportService,
    private router: Router,
    private toastService: ToastService,
    private validator: Validator,
    private validationController: ValidationController,
    private deleteDialogService: DeleteDialogService,
    private dialogService: DialogService,
    private utility: Utility,
    private prompt: Prompt,
    private netOrderItemService: NetOrderItemService
  ) {
  }

  private activate(params) {
    this.netOrderService
      .get(params.Id)
      .then((res) => {
        this.netorder = res;
        this.originalObject = JSON.parse(JSON.stringify(res));

        ValidationRules
          .ensure('CustomerId').required()
          .on(this.netorder);

      })
      .catch((err) => this.errorService.handleError(err));

  }

  private saveNetOrder() {
    this.netOrderService
      .put(this.netorder, this.netorder.Id)
      .then((res) => {
        this.toastService.showSuccess('order.updated');
        this.originalObject = null;
        
        this.router.navigateToRoute('order-detail', { Id: this.netorder.Id, NetId: this.netorder.NetId });
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private validateBeforeSaveOrder() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.netorder)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.saveNetOrder();
        }
      });
  }

  private confirmBeforeDeleteOrder() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.deleteOrder();
      },
      'dialog.deleteOrderHeading',
      'dialog.deleteOrderAreYouSure',
      'dialog.deleteOrder'
    );
  }

  private deleteOrder() {

    this.netOrderService
    .checkCanDeleteNetOrder(this.netorder.Id)
    .then((res) => {

      if (res.CanDelete === false) {
        return this.toastService.showError('order.deleteDeclined');
      }

      this.netOrderService
      .deleteNetOrder(this.netorder.Id)
      .then((ress) => {
        this.toastService.showSuccess('order.deleted');
        this.router.navigateToRoute('order-list');
      })
      .catch((err) => this.errorService.handleError(err));
    })
    .catch((err) => this.errorService.handleError(err));

  }

  private canDeactivate() {
    if (this.originalObject && !this.utility.areEqual(this.netorder, this.originalObject)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'save') {
            // Save the service and let that function handle the rest of the logic
            this.validateBeforeSaveOrder();
            return false;
          } else {
            return true;
          }
        }
      });
    } else {
      return true;
    }
  }

}
